<template>
    <div class="agb-container">
      <h1>Allgemeine Geschäftsbedingungen (AGB) für Newsletter-Abonnement</h1>
  
      <section>
        <h2>1. Geltungsbereich</h2>
        <p>Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Abonnenten des Newsletters von Rebberg im Stucki bei Bern. Mit der Anmeldung zum Newsletter akzeptieren Sie diese AGB.</p>
      </section>
  
      <section>
        <h2>2. Anmeldung und Bestätigung</h2>
        <p>2.1. Um den Newsletter zu erhalten, müssen Sie Ihre E-Mail-Adresse in das dafür vorgesehene Anmeldeformular auf unserer Website eingeben.</p>
        <p>2.2. Nach der Anmeldung erhalten Sie eine Bestätigungs-E-Mail. Ihre Anmeldung ist erst abgeschlossen, wenn Sie den darin enthaltenen Bestätigungslink anklicken (Double-Opt-In-Verfahren).</p>
      </section>
  
      <section>
        <h2>3. Erhebung und Verwendung von Daten</h2>
        <p>3.1. Im Rahmen der Anmeldung zum Newsletter erheben wir Ihre E-Mail-Adresse.</p>
        <p>3.2. Wir verwenden Ihre E-Mail-Adresse ausschließlich für den Versand des Newsletters. Der Newsletter enthält Informationen zu unseren Produkten, Dienstleistungen und aktuellen Angeboten.</p>
      </section>
  
      <section>
        <h2>4. Datenschutz</h2>
        <p>4.1. Ihre Daten werden vertraulich behandelt und nicht an Dritte weitergegeben.</p>
        <p>4.2. Weitere Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung.</p>
      </section>
  
      <section>
        <h2>5. Abmeldung</h2>
        <p>5.1. Sie können den Newsletter jederzeit abbestellen. Hierzu finden Sie am Ende jedes Newsletters einen entsprechenden Abmeldelink.</p>
        <p>5.2. Alternativ können Sie uns eine E-Mail mit dem Betreff "Abmeldung Newsletter" an <a href="mailto:info@fahrschule-herren.ch">info@fahrschule-herren.ch</a> senden.</p>
      </section>
  
      <section>
        <h2>6. Änderungen der AGB</h2>
        <p>6.1. Wir behalten uns das Recht vor, diese AGB jederzeit zu ändern. Über wesentliche Änderungen werden wir Sie rechtzeitig informieren.</p>
        <p>6.2. Die aktuellen AGB sind jederzeit auf unserer Website einsehbar.</p>
      </section>
  
      <section>
        <h2>7. Haftung</h2>
        <p>7.1. Wir übernehmen keine Haftung für die Aktualität, Vollständigkeit oder Richtigkeit der bereitgestellten Inhalte im Newsletter.</p>
        <p>7.2. Für Schäden, die durch die Nutzung des Newsletters entstehen, haften wir nur bei Vorsatz oder grober Fahrlässigkeit.</p>
      </section>
  
      <section>
        <h2>8. Anwendbares Recht und Gerichtsstand</h2>
        <p>8.1. Es gilt schweizerisches Recht.</p>
        <p>8.2. Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen AGB ist der Sitz von Rebberg im Stucki bei Bern.</p>
      </section>
  
      <section>
        <h2>9. Kontakt</h2>
        <p>Für Fragen zu unserem Newsletter und diesen AGB können Sie uns unter folgenden Kontaktdaten erreichen:</p>
        <p>Rebberg im Stucki bei Bern<br>
        <a href="mailto:info@fahrschule-herren.ch">info@fahrschule-herren.ch</a><br>
        Freiburgstrasse 67 3174 Thörishaus<br>
        079 415 22 29</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AGBPage'
  }
  </script>
  
  <style scoped>
  .agb-container {
    scroll-snap-type: none !important;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .agb-container h1, .agb-container h2 {
    color: #333;
  }
  
  .agb-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .agb-container h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .agb-container p {
    margin-bottom: 10px;
  }
  
  .agb-container a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .agb-container a:hover {
    text-decoration: underline;
  }
  </style>
  