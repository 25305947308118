export default {
  "home": {
    "starterPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neuer Rebberg esteht"])}
    },
    "welcome": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für dein Interesse an unserem Rebberg in 3174, im Stucki bei Bern.\n\nEin Rebberg an der Grenze von zwei politischen Gemeinden.\nEin Rebberg zwischen Bern und Freiburg.\nEin Rebberg zwischen See- und Mittelland.\nEin heranwachsender Rebberg, der sicher allen Freude machen wird."])},
      "signUpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du willst Informiert bleiben?"])},
      "signUpSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann klick auf registireren und wähle aus welche Infos du erhalten willst"])}
    },
    "customerMobile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Infos"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registieren >"])},
      "vineOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Wein Fertig ist"])},
      "allInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich will alle Infos"])},
      "buttonTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Registieren"])}
    },
    "timeLine": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Reise"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke auf eine Karte für mehr Infos"])},
      "cards": [
        {
          "key": 1,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idee"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Der erste Rebberg in Thörishaus wurde auf dem Gummen angelegt. Dieser Rebberg wurde von mir Peter Herren mit grossem Interesse stark verfolgt. Es hat sich sehr schnell gezeigt, dass der Südhang im Stucki bei Bern ideales Klima ist für den Weinbau und erst recht so nahe an der Stadt Bern. </p>"])}
        },
        {
          "key": 2,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewiligung"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blup blup"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai Chef"])}
        },
        {
          "key": 3,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbereitung"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blup blup"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai Chef"])}
        },
        {
          "key": 4,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflanzbeet"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blup blup"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai Chef"])}
        },
        {
          "key": 5,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflanzen"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blup blup"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai Chef"])}
        },
        {
          "key": 6,
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflege & Drahtaufbau"])},
          "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blup blup"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai Chef"])}
        }
      ]
    }
  }
}