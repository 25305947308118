import axios from "axios";

export default {
    addCustomer(data) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.3174.ch', data).then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        })

    }
}