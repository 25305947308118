<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-info">
          <p>Rebberg im Stucki bei Bern</p>
          <p>Eigentümer: Peter Herren</p>
          <p>Mobilnummer: <a href="tel:+41794152229">079 415 22 29</a></p>
          <p>E-Mail: <a href="mailto:info@fahrschule-herren.ch">info@fahrschule-herren.ch</a></p>
          <p>Adresse: Freiburgstrasse 67, 3174 Thörishaus</p>
        </div>
        <div class="footer-links">
          <router-link to="/impressum" class="impressum-link">Impressum</router-link>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterHome'
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    margin-top: 10vh;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-info p {
    margin: 5px 0;
  }
  
  .footer-info a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-info a:hover {
    text-decoration: underline;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .impressum-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .impressum-link:hover {
    text-decoration: underline;
  }
  </style>
  