<template>
    <div>
        <button @click="counter == 1 ? counter = 6 : counter--">&lt;&lt;</button>
        <img :src="require('../assets/slider/' + counter + '.jpg')" alt="Graphic">
        <button @click="counter == 6 ? counter = 1 : counter++">>></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            counter: 1,
        }
    },
}
</script>

<style scoped>
div {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}

img {
    width: 80%;
    aspect-ratio: 14/9;
    object-fit: cover;
}

button {
    font-size: 20px;
    margin: 10px;
    font-weight: bolder;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
 button {
    margin: auto;
 }
}
</style>