<template>
    <div class="impressum-container">
      <h1>Impressum</h1>
      <p><strong>Rebberg im Stucki bei Bern</strong></p>
      <p>Eigentümer: Peter Herren</p>
      <p>Mobilnummer: <a href="tel:+41794152229">079 415 22 29</a></p>
      <p>E-Mail: <a href="mailto:info@fahrschule-herren.ch">info@fahrschule-herren.ch</a></p>
      <p>Adresse: Freiburgstrasse 67, 3174 Thörishaus</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImpressumHerren'
  }
  </script>
  
  <style scoped>
  .impressum-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .impressum-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .impressum-container p {
    margin: 10px 0;
  }
  
  .impressum-container a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .impressum-container a:hover {
    text-decoration: underline;
  }
  </style>
  