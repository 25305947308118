<template>
    <div>
      <div class="button-container">
        <button @click="modalToggle">{{$t('home.customerMobile.register')}}</button>
      </div>
      <div class="modal" v-if="modalOpen">
        <div class="modal-content">
          <h2 @click="modalToggle" class="close-btn">X</h2>
          <div class="title-container">
            <h1>{{$t('home.customerMobile.title')}}</h1>
          </div>
          <div class="form-container">
            <input v-model="customerInfo.email" placeholder="E-Mail" class="input-field" />
            <div class="checkbox-container">
              <label>
                <input type="checkbox" v-model="customerInfo.infoType.vineOnly" class="checkbox" />
                <span>{{$t('home.customerMobile.vineOnly')}}</span>
              </label>
            </div>
            <div class="checkbox-container">
              <label>
                <input type="checkbox" v-model="customerInfo.infoType.tours" class="checkbox" />
                <span>{{$t('home.customerMobile.allInfos')}}</span>
              </label>
            </div>
            <div class="checkbox-container">
              <label>
                <input type="checkbox" v-model="acceptedAGB" class="checkbox" />
                <span>
                  Ich akzeptiere die
                  <router-link to="/agb">AGB</router-link>
                </span>
              </label>
            </div>
          </div>
          <div class="button-container">
            <button @click="addCustomer" :disabled="!acceptedAGB" class="submit-btn">{{$t('home.customerMobile.buttonTxt')}}</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApiService from '@/services/ApiService';
  
  export default {
    name: "customerMobile",
    data() {
      return {
        modalOpen: false,
        acceptedAGB: false,
        customerInfo: {
          email: "",
          infoType: {
            vineOnly: true,
            firstGrape: true,
            tours: true,
          }
        }
      }
    },
    methods: {
      modalToggle() {
        this.modalOpen = !this.modalOpen;
        if (this.modalOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'scroll';
        }
      },
      async addCustomer() {
        if (!this.acceptedAGB) return;
  
        await ApiService.addCustomer({
          email: this.customerInfo.email,
          firstGrape: this.customerInfo.infoType.firstGrape,
          tours: this.customerInfo.infoType.tours,
          vineOnly: this.customerInfo.infoType.vineOnly
        });
        this.modalToggle();
      }
    }
  }
  </script>
  
  <style scoped>
  /* The Modal (background) */
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    margin: 5% auto;
    padding: 20px;
    border: none;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 1.5em;
    color: #888;
  }
  
  /* Title Container */
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
  
  /* Form Container */
  .form-container {
    margin-top: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  /* Checkbox Container */
  .checkbox-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkbox {
    margin-right: 10px;
  }
  
  .checkbox + span {
    display: inline-block;
    margin-left: 10px;
  }
  
  /* Button Container */
  .button-container {
    margin-top: 20px;
    justify-content: center;
  }
  
  button, .submit-btn {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 15px 20px;
    cursor: pointer;
  }
  
  button:hover, .submit-btn:hover {
    background-color: #333;
  }
  
  button:disabled, .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive Styles */
  @media (min-width: 600px) {
    .modal-content {
      width: 60%;
    }
  }
  
  @media (min-width: 900px) {
    .modal-content {
      width: 40%;
    }
  }
  </style>
  