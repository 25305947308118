<template>
    <div class="modal" v-if="open">
        <div class="modal-content">
            <div class="title-container">
                <h1>{{ title }}</h1>
                <h2 @click="toggle">X</h2>
            </div>
            <div class="slot-container">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PopUp",
    props: ['title', 'lol'],
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggle() {
            this.open = !this.open
            if (this.open) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'scroll'
            }
        }
    }
}
</script>
<style>
.modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    padding-top: 50px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    min-height: 80%;
    margin: auto;
    border: 1px solid #888;
    padding-bottom: 30px;
    width: 80%;
}

.title-container {
    display: flex;
    justify-content: center;
}

.title-container h2 {
    position: absolute;
    right: 12%;
    cursor: pointer;
}

.slot-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
}

.pic {
    width: 80%;
    height: 200px;
    object-fit: cover;
}

@media screen and (min-width: 600px) {
    .slot-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .container {
        padding: 20px;
    }
}
</style>