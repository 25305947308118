<template>
  <div id="container">
    <!--Starter Page-->
    <div id="start-container">
      <h1>{{ $t('home.starterPage.title') }}</h1>
      <video autoplay muted loop>
        <source src="../assets/IMG/Spatenstich zum Rebberg.mp4" type="video/mp4">
      </video>
    </div>
    <div id="mobile-container">
      <h1>{{$t('home.welcome.title')}}</h1>
      <p style="white-space: pre-line; text-align: left" class="leader">{{$t('home.welcome.subTitle')}}</p>
      <div class="container">
        <div class="slot-container">
          <div>
            <h2>{{$t('home.welcome.signUpTitle')}}</h2>
            <p>{{$t('home.welcome.signUpSubTitle')}}</p>
            <CustomerMobile/>
          </div>
          <div class="slot-sub-container">
            <img src="../assets/IMG/IMG-20230803-WA0001.jpg" class="pic"/>
          </div>
        </div>
      </div>
    </div>
    <!--Time Line-->
    <div>
      <TimeLine/>
    </div>
    <!--Foto Gallerie-->
    <div id="mobile-container">
      <h1>Medien Gallerie</h1>
      <GallerieSlider/>

      <video id="video" controls loop>
        <source src="../assets/IMG/Spatenstich zum Rebberg.mp4" type="video/mp4">
      </video>
    </div>
    <FooterHome></FooterHome>
  </div>
</template>

<script>
import CustomerMobile from '@/components/CustomerMobile.vue';
//import PopUp from '@/components/PopUp.vue';
import TimeLine from '@/components/TimeLine.vue';
import GallerieSlider from '@/components/GallerieSlider.vue';
import FooterHome from '@/components/FooterHome.vue'


export default {
  name: 'HomeView',
  components: {
    CustomerMobile,
    //PopUp,
    TimeLine,
    GallerieSlider,
    FooterHome
  }
}
</script>

<style>
#container {
  scroll-snap-type: y mandatory;
}

#start-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  scroll-snap-align: start;
}

#start-container h1 {
  color: white;
  inline-size: calc(100vw / 3 * 2);
  overflow-wrap: break-word;
  text-align: left;
  padding-left: 20px;
  z-index: 1;
}

#start-container video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
}

#mobile-container {
  min-height: 100vh;
  scroll-snap-align: start;
  padding-top: 50px;
}

.slot-container {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
}

.slot-sub-container{
  display: flex;
  justify-content: center;
}

.pic {
  width: 80%;
  height: 400px;
  object-fit: cover;
}

.container {
  padding: 20px;
}

#video {
  width: 80%;
}

@media screen and (min-width: 600px) {
  #mobile-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (min-width: 600px) {
  .slot-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
  }
  .container {
      padding: 20px;
  }
}

@media screen and (max-width: 600px) {
  .pic {
    display: none;
  }
}
</style>
